import { Box, LightMode } from '@chakra-ui/react'

/* This is a workaround for forcing light mode on and making children Chakra text components follow that */
/* https://github.com/chakra-ui/chakra-ui/issues/6916#issuecomment-1304080946 */
export const LightModeWrapper = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <LightMode>
      <Box bg="chakra-body-bg" color="chakra-body-text" data-theme="light">
        {children}
      </Box>
    </LightMode>
  )
}
