import { Box, Flex, Grid, Text } from '@chakra-ui/layout'
import Image from 'next/image'
import { type ComponentProps, Fragment } from 'react'

import { HarvardMedicalSchoolLogo } from './HarvardMedicalSchoolLogo'
import { JohnHopkinsLogo } from './JohnHopkinsLogo'
import { McGillLogo } from './McGillLogo'
import { PennLogo } from './PennLogo'
import { StanfordMedicineLogo } from './StanfordMedicineLogo'

const staticImages = [
  { height: 30, src: 'Cedars Sinai Medical Center logo.svg' },
  { height: 40, src: 'dark-1.svg' },
  { height: 30, src: 'duke_logo.svg' },
  { height: 26, src: 'header-logo--desktop.svg' },
  { height: 30, src: 'logo_blue.svg' },
  { height: 30, src: 'logo-ccf.svg' },
  { height: 30, src: 'logo-columbia--white.svg' },
  { height: 46, src: 'Mass_General_Brigham_logo.svg' },
  { height: 24, src: 'logo-ucla.svg' },
  { height: 30, src: 'logo-uhn.svg' },
  { height: 36, src: 'sunnybrook.svg' },
  { height: 30, src: 'logo.svg' },
  { height: 30, src: 'ucsf.svg' },
  { height: 44, src: 'mayo-clinic-logo.svg' },
  { height: 52, src: 'MD_Anderson_Cancer_Center_logo.svg' },
  { height: 52, src: 'Mount_Sinai_Health_System_logo.svg' },
  { height: 30, src: 'Northwestern Medicine logo.svg' },
  { height: 30, src: 'nyp.svg' },
  { height: 30, src: 'Yale_University_logo.svg' },
].map((p) => ({ ...p, src: `/logos/${p.src}` }))

function LogoCell(props: ComponentProps<typeof Flex>) {
  const { children, ...rest } = props
  return (
    <Flex
      alignItems="center"
      borderBottomWidth={1}
      borderColor="gray.100"
      borderRightWidth={1}
      height="84px"
      justifyContent="center"
      minW={0}
      px={[4, 8]}
      {...rest}
    >
      <Box opacity={0.6}>{children}</Box>
    </Flex>
  )
}

export function LogoGrid() {
  const componentLogos = [
    <JohnHopkinsLogo fill={'black'} key={'hopkins'} size={156} />,
    <StanfordMedicineLogo
      fill={'black'}
      fillAlternate={'white'}
      key="stanford"
      size={124}
    />,
    <HarvardMedicalSchoolLogo
      fill={'black'}
      fillAlternate={'white'}
      key="harvard"
      size={128}
    />,
    <PennLogo key="penn" size={100} textFill={'black'} />,
    <McGillLogo
      fill={'black'}
      fillAlternate={'white'}
      key="mcgill"
      size={110}
    />,
  ]

  return (
    <Grid
      borderColor="gray.100"
      borderLeftWidth={1}
      borderTopWidth={1}
      templateColumns={[
        '1fr 1fr',
        '1fr 1fr',
        '1fr 1fr 1fr 1fr',
        '1fr 1fr 1fr 1fr 1fr 1fr',
      ]}
    >
      {componentLogos.map((c, i) => {
        return (
          <LogoCell key={i} order={[1, 'initial']}>
            {c}
          </LogoCell>
        )
      })}

      {staticImages.map((s, i) => {
        return (
          <Fragment key={s.src}>
            {i === 3 && (
              <Flex
                alignItems={'center'}
                borderBottomWidth={1}
                borderColor="gray.100"
                borderRightWidth={1}
                fontSize={20}
                fontWeight={'semibold'}
                gridColumn={['span 2', 'span 2', 'span 4', 'span 2']}
                gridRow={['span 1', 'span 1', 'span 1', 'span 3']}
                order={[0, 'initial']}
                textAlign={'center'}
              >
                <Box flex={1} px="4" py="8">
                  <Text color="gray.900">
                    Trusted by 900,000+ medical professionals in 10,000+ leading
                    healthcare centers.
                  </Text>
                </Box>
              </Flex>
            )}
            <LogoCell
              display={i > 10 ? ['none', 'none', 'none', 'flex'] : undefined}
              order={[1, 'initial']}
            >
              <Image
                alt=""
                className="object-contain"
                height={30}
                src={s.src}
                style={{
                  height: `${s.height}px`,
                }}
                width={200}
              />
            </LogoCell>
          </Fragment>
        )
      })}
    </Grid>
  )
}
