import { trpc } from '@/lib/trpcClient'
import { mixpanel } from '@/modules/mixpanel'
import { useToast } from '@chakra-ui/react'
import { captureException } from '@sentry/nextjs'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'

import { getHostUrl } from './getHostUrl'
import { useProfile } from './useProfile'

const key = 'pathway.promo'
const keyExpiry = 'pathway.promoExpiry'
function clearLocalStorage() {
  localStorage.removeItem(key)
  localStorage.removeItem(keyExpiry)
}

export function usePromoCode() {
  const router = useRouter()
  const [promoCode, setPromoCode] = useState('')
  const [isReady, setIsReady] = useState(false)
  const createPaymentSession = trpc.payments.promoOfferSession.useMutation()
  const [isAwaitingUpgradeResponse, setAwaitingUpgradeResponse] =
    useState<boolean>(false)
  const isRedirecting = useRef(false)
  const toast = useToast()

  const { isFetchingProfile, isLoggedOut, profile } = useProfile()

  const clearPromoCode = useCallback(() => {
    setPromoCode('')
    clearLocalStorage()
  }, [])

  async function checkout() {
    if (isAwaitingUpgradeResponse) return
    mixpanel.track('promo-checkout')
    if (isLoggedOut) {
      await router.push('/auth/sign-up')
      return
    }
    try {
      setAwaitingUpgradeResponse(true)
      const resp = await createPaymentSession.mutateAsync({
        promoCode: promoCode,
        successUrl: `${getHostUrl()}/account/subscription/processing`,
      })

      if (!resp.url) {
        isRedirecting.current = false
        throw new Error('Missing checkout session link')
      }
      isRedirecting.current = true
      clearLocalStorage()
      window.document.location = resp.url
    } catch (err) {
      isRedirecting.current = false
      if ((err as Error).message.toLowerCase().includes('network')) return
      toast({
        position: 'top-right',
        status: 'error',
        title: (err as Error).message,
      })
      captureException(err)

      setAwaitingUpgradeResponse(false)
    }
  }

  useEffect(() => {
    const promoCodeFromQueryString = router.query.promoCode as string

    if (isFetchingProfile || profile?.hasSubscription) return

    if (promoCodeFromQueryString && promoCode === '') {
      setPromoCode(promoCodeFromQueryString)
      localStorage.setItem(key, promoCodeFromQueryString)
      localStorage.setItem(
        keyExpiry,
        new Date(Date.now() + 48 * 60 * 60 * 1000).toISOString(),
      )
    }
  }, [
    router.query.promoCode,
    isFetchingProfile,
    profile,
    router.isReady,
    promoCode,
  ])

  useEffect(() => {
    if (
      !router.isReady ||
      router.query.promoCode ||
      isFetchingProfile ||
      profile?.hasSubscription
    ) {
      setIsReady(!!profile?.hasSubscription || !!router.query.promoCode)
      return
    }
    const savedPromoCode = localStorage.getItem(key)
    const savedPromoCodeExpiry = localStorage.getItem(keyExpiry)
    if (savedPromoCodeExpiry && new Date(savedPromoCodeExpiry) < new Date()) {
      clearPromoCode()
    } else {
      setPromoCode(savedPromoCode ?? '')
    }
    setIsReady(true)
  }, [
    router.query.promoCode,
    isFetchingProfile,
    profile,
    router.isReady,
    clearPromoCode,
  ])

  return {
    checkout,
    clearPromoCode,
    isAwaitingUpgradeResponse,
    isReady,
    promoCode,
  }
}
