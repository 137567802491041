import { LightModeWrapper } from '@/components/LightModeBox'
import { trpc } from '@/lib/trpcClient'
import { mixpanel } from '@/modules/mixpanel'
import { getAIResponseShareLink } from '@/utils/getAIResponseShareLink'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import Link from 'next/link'
import { capitalize } from 'p-common/src/utils/format'
import { useEffect } from 'react'

function AIPost(props: { text: string; title: string }) {
  return (
    <Stack _hover={{ opacity: 0.7 }}>
      <Text color="gray.900" fontWeight="semibold">
        {props.title}
      </Text>
      <Text color="gray.600" fontSize={14}>
        {props.text}
      </Text>
      <Flex alignItems={'center'} color={'blue.500'} gap={0.5} mt={1}>
        <Text fontSize={13}>Read More</Text>
        <ChevronRightIcon />
      </Flex>
    </Stack>
  )
}

export function AIFeed() {
  const responseFeed = trpc.ai.feed.useQuery(
    { limit: 20 },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  )

  useEffect(() => {
    mixpanel.track('ai-feed-view')
  }, [])

  return (
    <LightModeWrapper>
      <div className="bg-white px-6 lg:px-8">
        <Box
          bg="chakra-body-bg"
          borderColor={'gray.200'}
          borderTopWidth={1}
          color="chakra-body-text"
          data-theme="light"
          py={16}
        >
          <Stack gap={7} maxWidth={640} mx="auto">
            <Text
              fontSize={36}
              fontWeight="semibold"
              letterSpacing={'-0.025rem'}
              mb={6}
              textAlign="center"
            >
              Feed
            </Text>
            {responseFeed.data?.map((resp) => {
              return (
                <Link
                  href={getAIResponseShareLink(resp)}
                  key={resp.uuid}
                  onClick={() =>
                    mixpanel.track('ai-feed-click', {
                      question: resp.question,
                    })
                  }
                >
                  <AIPost
                    text={resp.responseAnswerExcerpt}
                    title={capitalize(resp.question)}
                  />
                </Link>
              )
            })}
          </Stack>
        </Box>
      </div>
    </LightModeWrapper>
  )
}
