import { usePromoCode } from '@/utils/usePromoCode'
import { useBreakpointValue } from '@chakra-ui/react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import useWindowScroll from '@react-hook/window-scroll'
import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { useState } from 'react'

import { PromoCodeBanner } from './Home/PromoCodeBanner'

export const Navbar = ({
  showSignInInsteadOfBurger,
  variant = 'default',
}: {
  showSignInInsteadOfBurger?: boolean
  variant?: 'default' | 'minimal'
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const scrollY = useWindowScroll(0)
  const isMobileSreen = useBreakpointValue({
    base: true,
    sm: false,
  })
  const navigation = [
    ...(variant === 'default' ? [{ href: '/contact', name: 'Contact' }] : []),
    { href: '/about', name: 'About' },
    { href: '/organizations', name: 'Organizations' },
  ]

  const { checkout, clearPromoCode, isAwaitingUpgradeResponse, promoCode } =
    usePromoCode()

  return (
    <>
      {promoCode !== '' && (
        <PromoCodeBanner
          isLoading={isAwaitingUpgradeResponse}
          onAction={checkout}
          onClose={clearPromoCode}
          promoCode={promoCode}
        />
      )}
      <nav
        aria-label="Global"
        className={clsx(
          'sticky top-0 z-50 w-full bg-white',
          scrollY > 0 &&
            !isMobileSreen &&
            'sm:border-b sm:border-gray-200 sm:bg-opacity-80 sm:backdrop-blur-sm sm:backdrop-saturate-50',
          scrollY > 0 && isMobileSreen && 'border-b border-gray-200',
        )}
      >
        <div className="mx-auto flex max-w-6xl items-center justify-between p-4 px-6 lg:px-8">
          {/* Logo */}
          <div className="flex lg:flex-1 ">
            <Link className="-m-1.5 p-1.5" href="/">
              <span className="sr-only">Pathway</span>
              <Image
                alt="Pathway Logo"
                className="max-h-8"
                height={24}
                src="/marketing/pathway-logo-refreshed.svg"
                width={116}
              />
            </Link>
          </div>
          {/* Mobile hamburger button */}
          <div className="flex lg:hidden">
            {showSignInInsteadOfBurger ? (
              <Link
                className="text-sm font-medium leading-6 text-black"
                href="/auth/sign-in"
              >
                Sign in
              </Link>
            ) : (
              <button
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
                type="button"
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon aria-hidden="true" className="h-6 w-6" />
              </button>
            )}
          </div>
          {/* Desktop menu */}
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <Link
                className="text-sm leading-6 text-gray-900"
                href={item.href}
                key={item.name}
              >
                {item.name}
              </Link>
            ))}
          </div>
          <div className="hidden items-center lg:flex lg:flex-1 lg:justify-end">
            <Link
              className="text-sm font-medium leading-6 text-black"
              href="/auth/sign-in"
            >
              Sign in
            </Link>
            {variant === 'default' && (
              <Link
                className="hover:opacity-85 ml-4 rounded-md bg-black px-2.5 py-1 text-sm font-semibold text-white"
                href="/auth/new"
              >
                Sign up free
              </Link>
            )}
          </div>
        </div>
      </nav>

      {/* Mobile menu */}
      <Dialog
        as="div"
        className="lg:hidden"
        onClose={setMobileMenuOpen}
        open={mobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-7 py-4 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            {/* Fixes logo getting highlighted in Safari */}
            <Link className="block focus:outline-none sm:hidden" href="/">
              <Image
                alt="Pathway Logo"
                height={32}
                src="/marketing/pathway-logo-refreshed.svg"
                width={115}
              />
            </Link>
            <button
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
              type="button"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <Link
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-medium leading-7 text-gray-900 hover:bg-gray-50"
                    href={item.href}
                    key={item.name}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className="py-6">
                <Link
                  className="mx-0 mt-4 block rounded-lg bg-black px-3 py-2 text-center text-base font-medium leading-7 text-white hover:bg-gray-500"
                  href="/auth/new"
                >
                  Sign up free
                </Link>
                <Link
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-center text-base font-medium leading-7 text-gray-900 hover:bg-gray-50"
                  href="/auth/sign-in"
                >
                  Sign in
                </Link>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </>
  )
}
