import { mixpanel } from '@/modules/mixpanel'
import { Box, Spinner, useColorMode } from '@chakra-ui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { ArrowLongRightIcon } from '@heroicons/react/24/outline'

export const PromoCodeBanner = (props: {
  isLoading?: boolean
  onAction: () => void
  onClose: () => void
  promoCode: string
}) => {
  const { colorMode } = useColorMode()

  function hideBanner() {
    props.onClose()
    mixpanel.track('banner-discount-close')
  }

  return (
    <Box
      cursor="pointer"
      onClick={props.onAction}
      pt={{ base: '0.5rem', lg: 0 }}
    >
      <div
        className={`relative isolate flex items-center gap-x-6 overflow-hidden ${
          colorMode === 'light' ? 'bg-blue-600' : 'bg-blue-600'
        } px-6 py-2.5 sm:px-2 sm:before:flex-1`}
      >
        <div className="flex flex-wrap items-center justify-center gap-x-3 gap-y-2">
          {!props.isLoading && (
            <>
              <p
                className={`text-xs leading-5 text-white md:text-[14px]  md:leading-6`}
              >
                Promo code{' '}
                <span className="font-semibold">{`"${props.promoCode}"`}</span>{' '}
                applied
              </p>
              <ArrowLongRightIcon className={`h-5 w-5 text-white`} />
            </>
          )}
          {props.isLoading && <Spinner color="white" />}
        </div>
        <div className="flex flex-1 justify-end">
          <button
            className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
            type="button"
          >
            <span className="sr-only">Dismiss</span>
            <XMarkIcon
              aria-hidden="true"
              className={`h-5 w-5 ${
                colorMode === 'light' ? 'text-white' : 'text-black'
              }`}
              onClick={hideBanner}
            />
          </button>
        </div>
      </div>
    </Box>
  )
}
